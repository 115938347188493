<template>
    <div class="financialParent">
        <h3 class="titleMark">财务信息</h3>  
        <div class="showInfo" v-if="financialData">
            <div v-for="item,index in infoList" :key='index'>
                <h5>{{item}}</h5>
                <p>{{financialData[infoName[index]]}}</p>
            </div>
        </div>
        <div class="noInfo" v-else >
            <img src="../assets/imgs/no_record.png" alt="">
            <p>您当前无财务信息，请及时进行完善，以便财务结算。</p>
            <el-button type='primary' @click="dialogFormVisible = true">创建财务信息</el-button>
        </div>
        <el-dialog title="财务信息" :visible.sync="dialogFormVisible" >
            <div class="markWords">(以下信息关系到您的收益,请准确地填写真实有效的信息,我们将确保您的信息安全。)</div>
            <el-form :model="form" class="formParent">
                <el-form-item label="财务对象" :label-width="formLabelWidth">
                    <el-radio v-model="form.object_type" label="1">对私</el-radio>
                    <el-radio v-model="form.object_type" label="2">对公</el-radio>
                </el-form-item>
                <el-form-item label="开户地" :label-width="formLabelWidth">
                    <el-cascader
                        v-model="form.area"
                        :options="options"
                        >
                    </el-cascader>
                </el-form-item>
                <el-form-item label="收款银行" :label-width="formLabelWidth">
                    <el-input v-model="form.bank" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="银行账号" :label-width="formLabelWidth">
                    <el-input v-model="form.accountsreceivable" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="收款人姓名" :label-width="formLabelWidth">
                    <el-input v-model="form.name" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="收款人电话" :label-width="formLabelWidth">
                    <el-input v-model="form.user_telephone" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="收款人身份证" :label-width="formLabelWidth">
                    <el-input v-model="form.id_card" autocomplete="off"></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button class="cancel" @click="handleSure(1)">取 消</el-button>
                <el-button type="primary" @click="handleSure(2)">确 定</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
    import {mapState} from 'vuex'
    import {regionData,CodeToText} from 'element-china-area-data'
    import {getFinancialData,FinancialStore} from '../api/staticPages'
    export default {
        data() {
            return {
                infoList:['账户类型','开户地区','收款银行','收款账号','收款人姓名','收款人电话','收款人身份证号'],
                infoName:['accountsreceivable','area','bank','bankNumber','name','user_telephone','id_card'],
                financialData:null,
                dialogFormVisible: false,
                form: {
                    object_type: '',
                    area:'',
                    bank:'',
                    accountsreceivable:'',
                    name: '',
                    user_telephone:'',
                    id_card:'',
                },
                options:regionData,
                formLabelWidth: '120px' 
            }
        },
        computed: {
           ...mapState({
               userInfo:state=>state.user.userInfo
           })
        },
        methods: {
            handleSure(num){
                if(num===2){
                    const loading = this.$loading({
                        lock: true,
                        text: '加载中...',
                        spinner: 'el-icon-loading',
                        background: 'rgba(165, 165, 220, .1)'
                    });
                    let areaCode = this.form.area
                    if(areaCode && areaCode.length>0){
                        this.form.province =  CodeToText[areaCode[0]] 
                        this.form.city = CodeToText[areaCode[1]] 
                        this.form.area =  CodeToText[areaCode[2]]
                        // console.log(this.form)
                        FinancialStore(this.form).then(res=>{
                            loading.close()
                            let {status,message} = res.data
                            if(status === 1){
                                this.$message({
                                    showClose: true,
                                    message,
                                    type: 'warning'
                                })
                            }else{
                                this.$message({
                                    showClose: true,
                                    message,
                                    type: 'success'
                                })
                                this.dialogFormVisible = false
                            }
                        })
                    }else{
                        this.$message({
                            showClose: true,
                            message: '请选择开户地区！',
                            type: 'warning'
                        })
                        loading.close()
                    }
                }else{
                    this.dialogFormVisible = false
                }
            }  
        },
        created(){
            const loading = this.$loading({
                lock: true,
                text: '加载中...',
                spinner: 'el-icon-loading',
                background: 'rgba(165, 165, 220, .1)'
            });
            getFinancialData().then(res=>{
                this.financialData = res.data.data
                loading.close();
            })
        }
    }
</script>

<style lang="less" scoped>
    .financialParent{
        height: 100%;
        padding:20px;
        background: white;
        box-sizing: border-box;
        .titleMark{
            font-size: 18px;
            font-weight: 900;
            margin:20px;
        }
        .showInfo{
            padding: 40px;
            h5{
                font-size: 16px;
                font-weight: 900;
            }
            p{
                font-size: 14px;
                margin-top: 10px;
                margin-bottom:15px;
            }
        }
        .noInfo{
            height: 60%;
            display: flex;
            padding-right: 40px;
            flex-direction: column;
            justify-content:flex-end;
            align-items: center;
            p{
                margin: 30px;
            }
        }
        .markWords{
            color: chocolate;
            font-size: 14px;
            position: relative;
            top: -30px;
        }
        .formParent{
            z-index: 120;
        }
        .dialog-footer{
            display: flex;
            justify-content: center;
            align-items: center;
            .cancel{
                color: #787878 !important;
                margin-right: 30px;
            }
        }
    }
</style>